import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, withModifiers as _withModifiers, Fragment as _Fragment, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47f95984"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "empty-state"
}
const _hoisted_2 = {
  key: 1,
  class: "empty-state"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "user-details" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { for: "profilePic" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "user-details" }
const _hoisted_11 = { slot: "label" }
const _hoisted_12 = { slot: "label" }
const _hoisted_13 = { class: "section-header" }
const _hoisted_14 = { class: "user-details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_Image = _resolveComponent("Image")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                slot: "start",
                "default-href": "/tabs/users"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("User details")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (!_ctx.isUserFetched)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_ion_item, { lines: "none" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_spinner, {
                      color: "secondary",
                      name: "crescent",
                      slot: "start"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.translate("Fetching user details")), 1)
                  ]),
                  _: 1
                })
              ]))
            : (!Object.keys(_ctx.selectedUser).length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.translate("User not found")), 1)
                ]))
              : (_openBlock(), _createElementBlock("main", _hoisted_3, [
                  _createElementVNode("section", _hoisted_4, [
                    (_ctx.isUserFetched || Object.keys(_ctx.selectedUser).length)
                      ? (_openBlock(), _createBlock(_component_ion_card, {
                          key: 0,
                          class: "profile"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createVNode(_component_ion_item, { lines: "none" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_avatar, { slot: "start" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_Image, { src: _ctx.imageUrl }, null, 8, ["src"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_label, { class: "ion-margin-start" }, {
                                    default: _withCtx(() => [
                                      (_ctx.selectedUser.groupName)
                                        ? (_openBlock(), _createElementBlock("h1", _hoisted_5, _toDisplayString(_ctx.selectedUser.groupName), 1))
                                        : (_openBlock(), _createElementBlock("h1", _hoisted_6, _toDisplayString(_ctx.selectedUser.firstName) + " " + _toDisplayString(_ctx.selectedUser.lastName), 1)),
                                      _createElementVNode("p", null, _toDisplayString(_ctx.selectedUser.userLoginId), 1),
                                      (_ctx.selectedUser.userLoginId === _ctx.userProfile.userLoginId)
                                        ? (_openBlock(), _createBlock(_component_ion_badge, { key: 2 }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.translate("Your user")), 1)
                                            ]),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_button, {
                                    fill: "outline",
                                    onClick: _ctx.editName
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.translate('Edit')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["onClick"])
                                ]),
                                _: 1
                              })
                            ]),
                            (_ctx.isUserFetched)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                  _createVNode(_component_ion_item, {
                                    onClick: _ctx.openCreatedByUserDetail,
                                    detail: "",
                                    button: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        icon: _ctx.bodyOutline,
                                        slot: "start"
                                      }, null, 8, ["icon"]),
                                      (_ctx.isCreatedBySystem())
                                        ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.translate("Created by", { userLoginId: "🧞" })), 1)
                                            ]),
                                            _: 1
                                          }))
                                        : (_openBlock(), _createBlock(_component_ion_label, { key: 1 }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.translate("Created by", { userLoginId: _ctx.selectedUser.createdByUserLogin })), 1)
                                            ]),
                                            _: 1
                                          }))
                                    ]),
                                    _: 1
                                  }, 8, ["onClick"]),
                                  _createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        icon: _ctx.cameraOutline,
                                        slot: "start"
                                      }, null, 8, ["icon"]),
                                      (!_ctx.imageUrl)
                                        ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.translate("Add profile picture")), 1)
                                            ]),
                                            _: 1
                                          }))
                                        : (_openBlock(), _createBlock(_component_ion_label, { key: 1 }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.translate("Replace profile picture")), 1)
                                            ]),
                                            _: 1
                                          })),
                                      _createElementVNode("input", {
                                        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadImage && _ctx.uploadImage(...args))),
                                        class: "ion-hide",
                                        type: "file",
                                        accept: "image/*",
                                        id: "profilePic"
                                      }, null, 32),
                                      _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.translate("Upload")), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        icon: _ctx.cloudyNightOutline,
                                        slot: "start"
                                      }, null, 8, ["icon"]),
                                      _createVNode(_component_ion_toggle, {
                                        checked: _ctx.selectedUser.statusId === 'PARTY_DISABLED',
                                        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.updateUserStatus($event)), ["prevent"]))
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.translate("Disable user")), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["checked"])
                                    ]),
                                    _: 1
                                  })
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                  _createVNode(_component_ion_item, {
                                    detail: "",
                                    button: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        icon: _ctx.bodyOutline,
                                        slot: "start"
                                      }, null, 8, ["icon"]),
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.translate("Created by", {userLoginId: _ctx.selectedUser.createdByUserLogin})), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        icon: _ctx.cameraOutline,
                                        slot: "start"
                                      }, null, 8, ["icon"]),
                                      _createVNode(_component_ion_skeleton_text, { animated: "" })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        icon: _ctx.cloudyNightOutline,
                                        slot: "start"
                                      }, null, 8, ["icon"]),
                                      _createVNode(_component_ion_toggle, {
                                        checked: _ctx.selectedUser.statusId === 'PARTY_ENABLED',
                                        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.updateUserStatus($event)), ["prevent"]))
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.translate("Disable user")), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["checked"])
                                    ]),
                                    _: 1
                                  })
                                ]))
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_ion_card, {
                          key: 1,
                          class: "profile"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createVNode(_component_ion_item, { lines: "none" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_skeleton_text, {
                                    animated: "",
                                    style: {"width":"10%"}
                                  }),
                                  _createVNode(_component_ion_label, { class: "ion-margin-start" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_skeleton_text, { animated: "" }),
                                      _createVNode(_component_ion_skeleton_text, { animated: "" }),
                                      _createVNode(_component_ion_skeleton_text, { animated: "" }),
                                      _createVNode(_component_ion_skeleton_text, { animated: "" })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _createElementVNode("div", null, [
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    icon: _ctx.bodyOutline,
                                    slot: "start"
                                  }, null, 8, ["icon"]),
                                  _createVNode(_component_ion_skeleton_text, { animated: "" })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    icon: _ctx.cameraOutline,
                                    slot: "start"
                                  }, null, 8, ["icon"]),
                                  _createVNode(_component_ion_skeleton_text, { animated: "" })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, { lines: "none" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    icon: _ctx.cloudyNightOutline,
                                    slot: "start"
                                  }, null, 8, ["icon"]),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.translate("Disable user")), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_skeleton_text, {
                                    animated: "",
                                    style: {"width":"30%"}
                                  })
                                ]),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        }))
                  ]),
                  _createElementVNode("section", _hoisted_10, [
                    (_ctx.isUserFetched || _ctx.selectedUser.userLoginId)
                      ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_header, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate('Login details')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            (_ctx.selectedUser.userLoginId)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createVNode(_component_ion_list, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_item, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_label, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.translate('Username')), 1)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_ion_label, { slot: "end" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.selectedUser.userLoginId), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_item, {
                                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_UPDT_BLOCK_LOGIN)
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_toggle, {
                                            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.updateUserLoginStatus($event)), ["prevent"])),
                                            checked: _ctx.selectedUser.enabled == 'N'
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.translate("Block login")), 1)
                                            ]),
                                            _: 1
                                          }, 8, ["checked"])
                                        ]),
                                        _: 1
                                      }, 8, ["disabled"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_button, {
                                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.resetPassword())),
                                    fill: "outline",
                                    color: "warning",
                                    expand: "block"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.translate('Reset password')), 1)
                                    ]),
                                    _: 1
                                  })
                                ], 64))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createVNode(_component_ion_list, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_item, { lines: "full" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_input, {
                                            "label-placement": "fixed",
                                            name: "username",
                                            modelValue: _ctx.username,
                                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.username) = $event)),
                                            id: "username"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_11, [
                                                _createTextVNode(_toDisplayString(_ctx.translate("Username")) + " ", 1),
                                                _createVNode(_component_ion_text, { color: "danger" }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode("*")
                                                  ]),
                                                  _: 1
                                                })
                                              ])
                                            ]),
                                            _: 1
                                          }, 8, ["modelValue"])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_item, {
                                        ref: "password",
                                        lines: "none"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_input, {
                                            "label-placement": "fixed",
                                            placeholder: _ctx.translate('Default password'),
                                            name: "password",
                                            modelValue: _ctx.password,
                                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.password) = $event)),
                                            id: "password",
                                            type: _ctx.showPassword ? 'text' : 'password',
                                            onIonInput: _ctx.validatePassword,
                                            onIonBlur: _ctx.markPasswordTouched,
                                            "helper-text": _ctx.translate('will be asked to reset their password when they login to OMS.', { name: _ctx.selectedUser.firstName ? _ctx.selectedUser.firstName : _ctx.selectedUser.groupName }),
                                            "error-text": _ctx.translate('Password should be at least 5 characters long and contain at least one number, alphabet and special character.')
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_12, [
                                                _createTextVNode(_toDisplayString(_ctx.translate("Password")) + " ", 1),
                                                _createVNode(_component_ion_text, { color: "danger" }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode("*")
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _createVNode(_component_ion_button, {
                                                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword)),
                                                slot: "end",
                                                fill: "clear"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_ion_icon, {
                                                    icon: _ctx.showPassword ? _ctx.eyeOutline : _ctx.eyeOffOutline,
                                                    slot: "icon-only"
                                                  }, null, 8, ["icon"])
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }, 8, ["placeholder", "modelValue", "type", "onIonInput", "onIonBlur", "helper-text", "error-text"])
                                        ]),
                                        _: 1
                                      }, 512)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_button, {
                                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.createNewUserLogin())),
                                    fill: "outline",
                                    expand: "block"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.translate('Add credentials')), 1)
                                    ]),
                                    _: 1
                                  })
                                ], 64))
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_ion_card, { key: 1 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_header, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate('Login details')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_list, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate('Username')), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_skeleton_text, {
                                      animated: "",
                                      style: {"width":"40%"}
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("Block login")), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_skeleton_text, {
                                      animated: "",
                                      style: {"width":"40%"}
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_button, {
                              disabled: "",
                              fill: "outline",
                              color: "warning",
                              expand: "block"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate('Reset password')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })),
                    (_ctx.isUserFetched)
                      ? (_openBlock(), _createBlock(_component_ion_card, { key: 2 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_header, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate('Contact details')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_list, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, {
                                      icon: _ctx.mailOutline,
                                      slot: "start"
                                    }, null, 8, ["icon"]),
                                    _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.selectedUser?.emailDetails ? _ctx.selectedUser.emailDetails.email : _ctx.translate('Email')), 1)
                                      ]),
                                      _: 1
                                    }),
                                    (_ctx.selectedUser?.emailDetails)
                                      ? (_openBlock(), _createBlock(_component_ion_button, {
                                          key: 0,
                                          slot: "end",
                                          fill: "clear",
                                          color: "medium",
                                          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.openContactActionsPopover($event, 'email', _ctx.selectedUser.emailDetails.email)))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_icon, {
                                              slot: "icon-only",
                                              icon: _ctx.ellipsisVerticalOutline
                                            }, null, 8, ["icon"])
                                          ]),
                                          _: 1
                                        }))
                                      : (_openBlock(), _createBlock(_component_ion_button, {
                                          key: 1,
                                          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.addContactField('email'))),
                                          slot: "end",
                                          fill: "clear"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_icon, {
                                              slot: "icon-only",
                                              icon: _ctx.addCircleOutline
                                            }, null, 8, ["icon"])
                                          ]),
                                          _: 1
                                        }))
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, {
                                      icon: _ctx.callOutline,
                                      slot: "start"
                                    }, null, 8, ["icon"]),
                                    _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.selectedUser?.phoneNumberDetails ? _ctx.selectedUser.phoneNumberDetails.contactNumber : _ctx.translate('Phone number')), 1)
                                      ]),
                                      _: 1
                                    }),
                                    (_ctx.selectedUser?.phoneNumberDetails)
                                      ? (_openBlock(), _createBlock(_component_ion_button, {
                                          key: 0,
                                          slot: "end",
                                          fill: "clear",
                                          color: "medium",
                                          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.openContactActionsPopover($event, 'phoneNumber', _ctx.selectedUser.phoneNumberDetails.contactNumber)))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_icon, {
                                              slot: "icon-only",
                                              icon: _ctx.ellipsisVerticalOutline
                                            }, null, 8, ["icon"])
                                          ]),
                                          _: 1
                                        }))
                                      : (_openBlock(), _createBlock(_component_ion_button, {
                                          key: 1,
                                          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.addContactField('phoneNumber'))),
                                          slot: "end",
                                          fill: "clear"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_icon, {
                                              slot: "icon-only",
                                              icon: _ctx.addCircleOutline
                                            }, null, 8, ["icon"])
                                          ]),
                                          _: 1
                                        }))
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_item, { lines: "none" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, {
                                      icon: _ctx.businessOutline,
                                      slot: "start"
                                    }, null, 8, ["icon"]),
                                    _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.selectedUser.externalId ? _ctx.selectedUser.externalId : _ctx.translate('External ID')), 1)
                                      ]),
                                      _: 1
                                    }),
                                    (_ctx.selectedUser.externalId)
                                      ? (_openBlock(), _createBlock(_component_ion_button, {
                                          key: 0,
                                          slot: "end",
                                          fill: "clear",
                                          color: "medium",
                                          onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.openContactActionsPopover($event, 'externalId', _ctx.selectedUser.externalId)))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_icon, {
                                              slot: "icon-only",
                                              icon: _ctx.ellipsisVerticalOutline
                                            }, null, 8, ["icon"])
                                          ]),
                                          _: 1
                                        }))
                                      : (_openBlock(), _createBlock(_component_ion_button, {
                                          key: 1,
                                          onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.addContactField('externalId'))),
                                          slot: "end",
                                          fill: "clear"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_icon, {
                                              slot: "icon-only",
                                              icon: _ctx.addCircleOutline
                                            }, null, 8, ["icon"])
                                          ]),
                                          _: 1
                                        }))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_ion_card, { key: 3 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_header, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate('Contact details')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_list, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, {
                                      icon: _ctx.mailOutline,
                                      slot: "start"
                                    }, null, 8, ["icon"]),
                                    _createVNode(_component_ion_skeleton_text, { animated: "" })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, {
                                      icon: _ctx.callOutline,
                                      slot: "start"
                                    }, null, 8, ["icon"]),
                                    _createVNode(_component_ion_skeleton_text, { animated: "" })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_item, { lines: "none" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, {
                                      icon: _ctx.businessOutline,
                                      slot: "start"
                                    }, null, 8, ["icon"]),
                                    _createVNode(_component_ion_skeleton_text, { animated: "" })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("h1", null, _toDisplayString(_ctx.translate('Permissions')), 1)
                  ]),
                  _createElementVNode("section", _hoisted_14, [
                    (_ctx.isUserFetched)
                      ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_header, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate('Clearance')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, { lines: "none" }, {
                              default: _withCtx(() => [
                                (!_ctx.hasPermission(_ctx.Actions.APP_SUPER_USER) && _ctx.selectedUser.securityGroup?.groupId === 'SUPER')
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.translate('Security Group')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_label, { slot: "end" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.translate('Super')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ], 64))
                                  : (_openBlock(), _createBlock(_component_ion_select, {
                                      key: 1,
                                      label: _ctx.translate('Security Group'),
                                      interface: "popover",
                                      disabled: !_ctx.hasPermission(_ctx.Actions.APP_SECURITY_GROUP_CREATE) || !_ctx.selectedUser.userLoginId,
                                      value: _ctx.selectedUser.securityGroup?.groupId,
                                      onIonChange: _cache[15] || (_cache[15] = ($event: any) => (_ctx.updateSecurityGroup($event)))
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSecurityGroups(_ctx.securityGroups), (securityGroup) => {
                                          return (_openBlock(), _createBlock(_component_ion_select_option, {
                                            key: securityGroup.groupId,
                                            value: securityGroup.groupId
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(securityGroup.groupName || securityGroup.groupId), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["value"]))
                                        }), 128)),
                                        _createVNode(_component_ion_select_option, { value: "" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("None")), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }, 8, ["label", "disabled", "value"]))
                              ]),
                              _: 1
                            }),
                            (!_ctx.userProductStores.length)
                              ? (_openBlock(), _createBlock(_component_ion_button, {
                                  key: 0,
                                  disabled: !_ctx.hasPermission(_ctx.Actions.APP_UPDT_PRODUCT_STORE_CONFG),
                                  onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.selectProductStore())),
                                  fill: "outline",
                                  expand: "block"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, {
                                      icon: _ctx.addOutline,
                                      slot: "start"
                                    }, null, 8, ["icon"]),
                                    _createTextVNode(" " + _toDisplayString(_ctx.translate('Add to a product store')), 1)
                                  ]),
                                  _: 1
                                }, 8, ["disabled"]))
                              : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_list_header, { color: "light" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_label, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate('Product stores')), 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_ion_button, {
                                          disabled: !_ctx.hasPermission(_ctx.Actions.APP_UPDT_PRODUCT_STORE_CONFG),
                                          onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.selectProductStore()))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate('Add')) + " ", 1),
                                            _createVNode(_component_ion_icon, {
                                              slot: "end",
                                              icon: _ctx.addCircleOutline
                                            }, null, 8, ["icon"])
                                          ]),
                                          _: 1
                                        }, 8, ["disabled"])
                                      ]),
                                      _: 1
                                    }),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userProductStores, (store) => {
                                      return (_openBlock(), _createBlock(_component_ion_item, {
                                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_UPDT_PRODUCT_STORE_CONFG),
                                        key: store.productStoreId
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_label, null, {
                                            default: _withCtx(() => [
                                              _createElementVNode("h2", null, _toDisplayString(store.storeName || store.productStoreId), 1),
                                              _createElementVNode("p", null, _toDisplayString(_ctx.getRoleTypeDesc(store.roleTypeId)), 1)
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_ion_button, {
                                            slot: "end",
                                            fill: "clear",
                                            color: "medium",
                                            onClick: ($event: any) => (_ctx.openProductStoreActionsPopover($event, store))
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_icon, {
                                                slot: "icon-only",
                                                icon: _ctx.ellipsisVerticalOutline
                                              }, null, 8, ["icon"])
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"])
                                        ]),
                                        _: 2
                                      }, 1032, ["disabled"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }))
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_ion_card, { key: 1 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_header, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate('Clearance')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, { lines: "none" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  icon: _ctx.businessOutline,
                                  slot: "start"
                                }, null, 8, ["icon"]),
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate('Security Group')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_skeleton_text, {
                                  animated: "",
                                  style: {"width":"40%"}
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_button, {
                              disabled: "",
                              fill: "outline",
                              expand: "block"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  icon: _ctx.addOutline,
                                  slot: "start"
                                }, null, 8, ["icon"]),
                                _createTextVNode(" " + _toDisplayString(_ctx.translate('Add to a product store')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })),
                    (_ctx.isUserFetched)
                      ? (_openBlock(), _createBlock(_component_ion_card, { key: 2 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_header, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate('Fulfillment')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_list, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, {
                                  disabled: !_ctx.hasPermission(_ctx.Actions.APP_UPDT_PICKER_CONFG)
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_toggle, {
                                      onClick: _cache[18] || (_cache[18] = _withModifiers(($event: any) => (_ctx.updatePickerRoleStatus($event)), ["prevent"])),
                                      checked: _ctx.selectedUser.isWarehousePicker === true
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("Show as picker")), 1)
                                      ]),
                                      _: 1
                                    }, 8, ["checked"])
                                  ]),
                                  _: 1
                                }, 8, ["disabled"]),
                                _createVNode(_component_ion_item, {
                                  lines: "none",
                                  button: "",
                                  detail: "",
                                  disabled: !_ctx.hasPermission(_ctx.Actions.APP_UPDT_FULFILLMENT_FACILITY) || _ctx.selectedUser.securityGroup.groupId === 'INTEGRATION',
                                  onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.selectFacility()))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.getUserFacilities().length === 1 ? _ctx.translate('Added to 1 facility') : _ctx.translate('Added to facilities', { count: _ctx.getUserFacilities().length })), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_ion_card, { key: 3 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_header, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate('Fulfillment')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_list, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_skeleton_text, { animated: "" })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_item, { lines: "none" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_skeleton_text, { animated: "" })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })),
                    (_ctx.isUserFetched)
                      ? (_openBlock(), _createBlock(_component_ion_card, { key: 4 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_header, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate('Favorites')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_card_content, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate('Select your favorites to preselect them across all applications')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_list, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_select, {
                                      label: _ctx.translate('Product store'),
                                      interface: "popover",
                                      value: _ctx.selectedUser.favoriteProductStorePref?.userPrefValue ? _ctx.selectedUser.favoriteProductStorePref?.userPrefValue : '',
                                      onIonChange: _cache[20] || (_cache[20] = ($event: any) => (_ctx.updateFavoriteProductStore($event))),
                                      disabled: !_ctx.selectedUser?.userLoginId
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userProductStores, (productStore) => {
                                          return (_openBlock(), _createBlock(_component_ion_select_option, {
                                            key: productStore.productStoreId,
                                            value: productStore.productStoreId
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(productStore.storeName || productStore.productStoreId), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["value"]))
                                        }), 128)),
                                        _createVNode(_component_ion_select_option, { value: "" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("None")), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }, 8, ["label", "value", "disabled"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_item, { lines: "none" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_select, {
                                      label: _ctx.translate('Shopify shop'),
                                      interface: "popover",
                                      value: _ctx.selectedUser.favoriteShopifyShopPref?.userPrefValue ? _ctx.selectedUser.favoriteShopifyShopPref?.userPrefValue : '',
                                      onIonChange: _cache[21] || (_cache[21] = ($event: any) => (_ctx.updateFavoriteShopifyShop($event))),
                                      disabled: !_ctx.selectedUser?.userLoginId
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shopifyShopsForProductStore, (shopifyShop) => {
                                          return (_openBlock(), _createBlock(_component_ion_select_option, {
                                            key: shopifyShop.shopId,
                                            value: shopifyShop.shopId
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(shopifyShop.name || shopifyShop.shopId), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["value"]))
                                        }), 128)),
                                        _createVNode(_component_ion_select_option, { value: "" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("None")), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }, 8, ["label", "value", "disabled"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_ion_card, { key: 5 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_header, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate('Favorites')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_list, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_skeleton_text, { animated: "" })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_item, { lines: "none" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_skeleton_text, { animated: "" })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                  ])
                ]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}