import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-636d0a6f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { slot: "label" }
const _hoisted_2 = { slot: "label" }
const _hoisted_3 = { slot: "label" }
const _hoisted_4 = { class: "actions ion-margin-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                "default-href": "/create-user",
                slot: "start"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Create user")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            (!_ctx.isFacilityLogin())
              ? (_openBlock(), _createBlock(_component_ion_item, {
                  key: 0,
                  class: "ion-margin-bottom"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "start",
                      icon: _ctx.documentTextOutline
                    }, null, 8, ["icon"]),
                    _createVNode(_component_ion_select, {
                      label: _ctx.translate('Select template'),
                      interface: "popover",
                      modelValue: _ctx.userTemplateId,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userTemplateId) = $event)),
                      onIonChange: _ctx.updateUserTemplate
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userTemplates, (userTemplate) => {
                          return (_openBlock(), _createBlock(_component_ion_select_option, {
                            key: userTemplate.templateId,
                            value: userTemplate.templateId
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(userTemplate.templateName), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["label", "modelValue", "onIonChange"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            ((_ctx.selectedUserTemplate && _ctx.selectedUserTemplate.isUserLoginRequired || _ctx.isFacilityLogin()))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "floating",
                        modelValue: _ctx.formData.userLoginId,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.userLoginId) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, [
                            _createTextVNode(_toDisplayString(_ctx.translate('Username')) + " ", 1),
                            _createVNode(_component_ion_text, { color: "danger" }, {
                              default: _withCtx(() => [
                                _createTextVNode("*")
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        ref: "password",
                        "label-placement": "floating",
                        modelValue: _ctx.formData.currentPassword,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.currentPassword) = $event)),
                        type: _ctx.showPassword ? 'text' : 'password',
                        autocomplete: "new-password",
                        onIonInput: _ctx.validatePassword,
                        onIonBlur: _ctx.markPasswordTouched,
                        "helper-text": _ctx.translate('Password should be at least 5 characters long and contain at least one number, alphabet and special character.'),
                        "error-text": _ctx.translate('Password should be at least 5 characters long and contain at least one number, alphabet and special character.')
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            _createTextVNode(_toDisplayString(_ctx.translate("Password")) + " ", 1),
                            _createVNode(_component_ion_text, { color: "danger" }, {
                              default: _withCtx(() => [
                                _createTextVNode("*")
                              ]),
                              _: 1
                            })
                          ]),
                          _createVNode(_component_ion_button, {
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword)),
                            slot: "end",
                            fill: "clear"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                icon: _ctx.showPassword ? _ctx.eyeOutline : _ctx.eyeOffOutline,
                                slot: "icon-only"
                              }, null, 8, ["icon"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue", "type", "onIonInput", "onIonBlur", "helper-text", "error-text"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "floating",
                        modelValue: _ctx.formData.emailAddress,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.emailAddress) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_3, [
                            _createTextVNode(_toDisplayString(_ctx.isFacilityLogin() ? _ctx.translate('Reset password email') : _ctx.translate('Email')) + " ", 1),
                            (_ctx.selectedUserTemplate.templateId !== 'INTEGRATION')
                              ? (_openBlock(), _createBlock(_component_ion_text, {
                                  key: 0,
                                  color: "danger"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("*")
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { "ion-margin-top": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_toggle, {
                        disabled: _ctx.selectedUserTemplate.isPasswordChangeDisabled,
                        checked: _ctx.formData.requirePasswordChange,
                        "label-placement": "start",
                        justify: "space-between"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Require password reset on login")), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled", "checked"])
                    ]),
                    _: 1
                  })
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.selectedUserTemplate && _ctx.selectedUserTemplate.isEmployeeIdRequired && !_ctx.isFacilityLogin())
              ? (_openBlock(), _createBlock(_component_ion_item, { key: 2 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_input, {
                      label: _ctx.translate('Employee ID'),
                      "label-placement": "floating",
                      modelValue: _ctx.formData.externalId,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.externalId) = $event))
                    }, null, 8, ["label", "modelValue"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.selectedUserTemplate && _ctx.selectedUserTemplate.isProductStoreRequired && !_ctx.isFacilityLogin())
              ? (_openBlock(), _createBlock(_component_ion_item, { key: 3 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Product stores")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_button, {
                      slot: "end",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.addProductStores()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("selected", {storeCount: _ctx.selectedProductStores ? _ctx.selectedProductStores.length : 0})), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            ((_ctx.selectedUserTemplate && _ctx.selectedUserTemplate.isFacilityRequired) || _ctx.isFacilityLogin())
              ? (_openBlock(), _createBlock(_component_ion_list, { key: 4 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_list_header, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate('Select Facilities')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_button, {
                          fill: "clear",
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.addFacilities()))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("Add")) + " ", 1),
                            _createVNode(_component_ion_icon, {
                              slot: "end",
                              icon: _ctx.addCircleOutline
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facilities, (facility) => {
                      return (_openBlock(), _createBlock(_component_ion_item, {
                        key: facility.facilityId
                      }, {
                        default: _withCtx(() => [
                          (!_ctx.isFacilityLogin())
                            ? (_openBlock(), _createBlock(_component_ion_checkbox, {
                                key: 0,
                                checked: true,
                                onIonChange: ($event: any) => (_ctx.toggleFacilitySelection(facility))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(facility.facilityName || facility.facilityId) + " ", 1),
                                      _createElementVNode("p", null, _toDisplayString(facility.facilityId), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["onIonChange"]))
                            : (_openBlock(), _createBlock(_component_ion_label, { key: 1 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(facility.facilityName || facility.facilityId) + " ", 1),
                                  _createElementVNode("p", null, _toDisplayString(facility.facilityId), 1)
                                ]),
                                _: 2
                              }, 1024))
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ion_button, {
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.finishSetup()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Finish setup")) + " ", 1),
                  _createVNode(_component_ion_icon, {
                    slot: "end",
                    icon: _ctx.arrowForwardOutline
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                class: "ion-margin-top",
                fill: "outline",
                size: "small",
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.confirmSetupManually()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Setup manually")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                color: "medium",
                fill: "outline",
                size: "small",
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.finishAndCreateNewUser()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Finish and create new user")), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}